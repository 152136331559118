import React, {useState} from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import Layout from "../components/Layout"
import Container from '@material-ui/core/Container'
import { Link } from "gatsby"
import Typography from '@material-ui/core/Typography'

const AnimatedTypography = animated(Typography);

const useStyles = makeStyles(theme => ({
  card: {
    background: 'grey',
    borderRadius: '5px',
    boxShadow: '0px 10px 30px -5px rgba(0, 0, 0, 0.3)',
    transition: 'box-shadow 0.5s',
    border: '15px solid white',
    textAlign: 'center',
    display: 'flex',
    height: '300px',
    margin: 10,
    justifyContent: 'center', 
  },
  text: {
    margin: 'auto',
    color: 'black',
    willChange: 'transform',
    fontWeight: '500',
    width: '300px',
    height: '300px',

  }
}))


const calc = (x, y) => [(y - window.innerHeight / 2) / 30, -(x - window.innerWidth / 2) / 30, 1.1]
const trans = (x, y, s) => `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
const textTrans = (x, y, s) => `10px 10px 2px rgba(8,44,68,0.4)`
const oCalc  = (x) => `${x}`

function Card() {
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 }  }))
  const classes = useStyles()

  const [state, toggle] = useState(false)
  const {opacity} = useSpring({opacity: state ? 1 : 0  })

  return (
    <Container maxWidth='xs'>
    
    <animated.div
      className={classes.card}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
      >

      <AnimatedTypography
       variant='h2' 
       onMouseEnter={() => {toggle(!state)}} 
       onMouseLeave={() => toggle(!state)}
      style={{ transform: props.xys.interpolate(trans), opacity: opacity}}
      >
        <Link className={classes.text} to="/"> Explore </Link>  
      </AnimatedTypography>
    </animated.div>
    </Container>
  )
}

export default Card